import parse from "html-react-parser";
import React, { useContext, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { IoIosArrowUp } from "react-icons/io";

const DropdownQuestion = ({ question, answer }) => {
	return (
		<Container style={{ zIndex: 3 }} className=" position-relative">
			<Row className="align-items-center px-2 px-md-0 justify-content-end">
				<Col>
					<Row className="pb-4">
						<Col
							style={{ borderBottom: "1px solid #285DA8" }}
							xs={12}
							className="my-1 mx-0 px-0  "
						>
							<Accordion>
								<Card
									style={{ background: "transparent" }}
									className="border-0 text-black"
								>
									<Card.Header
										style={{ background: "transparent" }}
										className="border-0  px-0 p-0 m-0"
									>
										<ContextAwareToggle eventKey="0" className=" border-0 ">
											<div className="d-flex align-items-center">
												<p className="pb-0 inter-bold fs-5 mb-0 ">{question}</p>
											</div>
										</ContextAwareToggle>
									</Card.Header>
									<Accordion.Collapse eventKey="0">
										<Card.Body
											style={{ background: "transparent" }}
											className="pt-0 "
										>
											<Row>
												<Col className=" ">
													<p>{parse(answer)}</p>
												</Col>
											</Row>
										</Card.Body>
									</Accordion.Collapse>
								</Card>
							</Accordion>
						</Col>
					</Row>
				</Col>
			</Row>
		</Container>
	);
};

export default DropdownQuestion;

function ContextAwareToggle({ children, eventKey, callback }) {
	const currentEventKey = useContext(AccordionContext);
	const [turnArrow, setTurnArrow] = useState(false);

	const decoratedOnClick = useAccordionButton(eventKey, () => {
		callback && callback(eventKey);
		setTurnArrow(!turnArrow);
	});

	const isCurrentEventKey = currentEventKey === eventKey;

	return (
		<Card.Header
			className="border-0"
			style={{
				color: isCurrentEventKey ? "black" : "black",
				background: "transparent",
			}}
			onClick={decoratedOnClick}
		>
			<Container className="pe-0 ps-1" fluid={true}>
				<Row className="align-items-center">
					<Col
						className="d-flex justify-content-start align-items-center ps-0"
						xs={11}
					>
						{children}
					</Col>
					<Col xs={1} className="d-flex px-0  justify-content-start">
						<IoIosArrowUp
							className={`text-black fs-2 ${turnArrow ? "" : "rotate-down"}`}
						/>
					</Col>
				</Row>
			</Container>
		</Card.Header>
	);
}
